import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import theme from "../../../utils/theme"
import { stringToPrice } from "../../../utils/navigateToCart"

const MatelasStickyAddToCart = ({
  intl,
  productType,
  offerType,
  size,
  cart_text,
  discountPrice,
  regularPrice,
  handleAddTocart,
  active,
  id,
  page,
}) => {
  return (
    <Wrapper id={id} active={active}>
      <StickyBottom>
        <LeftWrapper>
          <LeftTopWrapper>
            <StickyProductSingleTitle
              dangerouslySetInnerHTML={{
                __html: `${
                  productType === "mousse" &&
                  size != "80x200cm" &&
                  size != "90x190cm" &&
                  size != "90x200cm" &&
                  offerType === "Pack"
                    ? "1 matelas mousse mémoire de forme + 2 oreillers universels"
                    : productType === "mousse" && offerType === "Pack"
                    ? "1 matelas mousse mémoire de forme + 1 oreiller universel"
                    : productType === "mousse" && offerType === "Seul"
                    ? "1 matelas mousse mémoire de forme"
                    : size != "80x200cm" &&
                      size != "90x190cm" &&
                      size != "90x200cm" &&
                      offerType === "Pack"
                    ? "1 matelas hybride premium + 2 oreillers universels"
                    : offerType === "Pack"
                    ? "1 matelas hybride premium + 1 oreiller universel"
                    : "1 matelas hybride premium"
                } ${size}`,
              }}
            />
          </LeftTopWrapper>
        </LeftWrapper>
        <RightWrapper>
          <PriceWrapper>
            {Number(stringToPrice(regularPrice)) ==
              Number(stringToPrice(discountPrice)) && (
              <ProductRegularPrice>
                {`${
                  process.env.GATSBY_CURRENCY_TYPE !== "eur"
                    ? process.env.GATSBY_CURRENCY
                    : ""
                }${discountPrice}${
                  process.env.GATSBY_CURRENCY_TYPE === "eur"
                    ? process.env.GATSBY_CURRENCY
                    : ""
                }`}
              </ProductRegularPrice>
            )}
            {Number(stringToPrice(regularPrice)) >
              Number(stringToPrice(discountPrice)) && (
              <ProductDiscountPrice>
                {`${
                  process.env.GATSBY_CURRENCY_TYPE !== "eur"
                    ? process.env.GATSBY_CURRENCY
                    : ""
                }${discountPrice}${
                  process.env.GATSBY_CURRENCY_TYPE === "eur"
                    ? process.env.GATSBY_CURRENCY
                    : ""
                }`}
              </ProductDiscountPrice>
            )}
            {Number(stringToPrice(regularPrice)) >
              Number(stringToPrice(discountPrice)) && (
              <ProductStrikePrice page={page}>
                {`${
                  process.env.GATSBY_CURRENCY_TYPE !== "eur"
                    ? process.env.GATSBY_CURRENCY
                    : ""
                }${regularPrice}${
                  process.env.GATSBY_CURRENCY_TYPE === "eur"
                    ? process.env.GATSBY_CURRENCY
                    : ""
                }`}
              </ProductStrikePrice>
            )}
          </PriceWrapper>
          <AddToCartWrapper>
            <LyneAddToCart
              style={{ minWidth: "100%" }}
              onClick={() => {
                handleAddTocart && handleAddTocart()
              }}
              dangerouslySetInnerHTML={{
                __html: cart_text,
              }}
              page={page}
            />
          </AddToCartWrapper>
        </RightWrapper>
      </StickyBottom>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  visibility: ${({ active }) => (active ? "visible" : "hidden")};
  opacity: ${({ active }) => (active ? 1 : 0)};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 10px 20px 15px;
  width: 100%;
  background: white;
  z-index: 3;
  box-shadow: 0 0 15px 2px #cecece80;
  transition: visibility 0s, opacity 0.5s linear;
`

const StickyBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1160px;
  width: 100%;
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    display: none;
  }
`

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 600px) {
    flex: 1;
    justify-content: space-between;
  }
`

const LeftTopWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StickyProductSingleTitle = styled.div`
  text-transform: uppercase;
  color: ${props => props.theme.colors.black.base};
  font-size: 24px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  font-style: normal;

  @media (max-width: 850px) {
    font-size: 21px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const PriceWrapper = styled.div`
  display: flex;
  margin: 0px 15px 0px 0px;
  align-items: center;

  @media (max-width: 850px) {
    margin-right: 10px;
  }

  @media (max-width: 600px) {
    margin-right: 0px;
  }
`

const ProductStrikePrice = styled.div`
  font-size: 18px;
  line-height: 1;
  font-family: "Gotham Book";
  color: ${props => props.theme.colors.black.base};
  font-weight: 500;
  position: relative;
  margin-left: 10px;

  :before {
    position: absolute;
    content: "";
    border-top: 1px solid;
    border-color: ${props => props.theme.colors.black.base};
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 480px) {
    font-size: 14px;
    margin-left: 6px;
  }
`

const ProductDiscountPrice = styled.div`
  font-size: 24px;
  line-height: 1;
  font-family: "Gotham Book";
  color: ${({ page }) =>
    page === "matelas-exclusif"
      ? theme.colors.primary.exclusif
      : theme.saleColor.priceColor};
  font-weight: bold;
  position: relative;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`

const ProductRegularPrice = styled.div`
  font-size: 24px;
  line-height: 1;
  font-family: "Gotham Book";
  color: ${props => props.theme.colors.black.base};
  font-weight: bold;
  position: relative;

  @media (max-width: 480px) {
    font-size: 18px;
  }
`

const AddToCartWrapper = styled.div`
  margin-left: 5px;
  flex: 1;
  position: relative;
  min-width: 200px;

  @media (max-width: 600px) {
    margin-left: 0px;
    min-width: 160px;
  }
`

const LyneAddToCart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background: ${({ page }) =>
    page === "matelas-exclusif" ? theme.colors.primary.exclusif : "#262626"};
  color: #fff;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 0px;
  cursor: pointer;
  font-family: "Museo";
  font-weight: bold;
  min-width: 210px;
  min-height: 42px;
  flex: 1;
  position: relative;
  margin-left: 10px;

  :hover {
    background: ${({ page }) =>
      page === "matelas-exclusif"
        ? theme.colors.primary.exclusif
        : theme.colors.button.black};
    color: #fff;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }
`

export default injectIntl(MatelasStickyAddToCart)
